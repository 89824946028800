/**
 * @generated SignedSource<<ffc7b558500209a65a6f9d8df87ef461>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "normOrganisationId",
    "variableName": "id"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NormOrganisationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "NormOrganisation",
        "kind": "LinkedField",
        "name": "normOrganisation",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NormParagraph_normOrganisation"
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Norm",
            "kind": "LinkedField",
            "name": "norm",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "NormParagraph",
            "kind": "LinkedField",
            "name": "normParagraphs",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "NormParagraph_normParagraph"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NormOrganisationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "NormOrganisation",
        "kind": "LinkedField",
        "name": "normOrganisation",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Norm",
            "kind": "LinkedField",
            "name": "norm",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasDoa",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "NormColumn",
                "kind": "LinkedField",
                "name": "normColumns",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "columnType",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "NormParagraph",
            "kind": "LinkedField",
            "name": "normParagraphs",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": (v6/*: any*/),
                "concreteType": "NormColumnAnswer",
                "kind": "LinkedField",
                "name": "normColumnAnswers",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "answer",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "normColumnId",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v6/*: any*/),
                "concreteType": "NormExplanation",
                "kind": "LinkedField",
                "name": "normExplanation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isApplicable",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isImplemented",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "content",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reasonLaw",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reasonContract",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reasonBestPractices",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reasonRisk",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v6/*: any*/),
                "concreteType": "NormLinkOrganisation",
                "kind": "LinkedField",
                "name": "normLinkOrganisations",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ManualParagraph",
                    "kind": "LinkedField",
                    "name": "manualParagraph",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v5/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "648e9d65856f27ec4615fc7cd7f0c4a8",
    "id": null,
    "metadata": {},
    "name": "NormOrganisationQuery",
    "operationKind": "query",
    "text": "query NormOrganisationQuery(\n  $id: ID!\n) {\n  normOrganisation(id: $id) {\n    ...NormParagraph_normOrganisation\n    databaseId\n    norm {\n      name\n      id\n    }\n    normParagraphs {\n      id\n      ...NormParagraph_normParagraph\n    }\n    id\n  }\n}\n\nfragment EditNormParagraph_normOrganisation on NormOrganisation {\n  id\n  databaseId\n  norm {\n    hasDoa\n    normColumns {\n      id\n      databaseId\n      columnType\n      name\n    }\n    id\n  }\n}\n\nfragment EditNormParagraph_normParagraph on NormParagraph {\n  normColumnAnswers(normOrganisationId: $id) {\n    databaseId\n    answer\n    normColumnId\n    id\n  }\n  normExplanation(normOrganisationId: $id) {\n    databaseId\n    content\n    isApplicable\n    isImplemented\n    reasonLaw\n    reasonContract\n    reasonBestPractices\n    reasonRisk\n    id\n  }\n}\n\nfragment NormParagraph_normOrganisation on NormOrganisation {\n  ...EditNormParagraph_normOrganisation\n  databaseId\n  norm {\n    ...ShowNormParagraph_norm\n    id\n  }\n}\n\nfragment NormParagraph_normParagraph on NormParagraph {\n  databaseId\n  name\n  number\n  ...ShowNormParagraph_normParagraph\n  ...EditNormParagraph_normParagraph\n  normExplanation(normOrganisationId: $id) {\n    content\n    id\n  }\n  normLinkOrganisations(normOrganisationId: $id) {\n    status\n    databaseId\n    manualParagraph {\n      databaseId\n      number\n      name\n      id\n    }\n    id\n  }\n}\n\nfragment ShowNormParagraph_norm on Norm {\n  hasDoa\n  normColumns {\n    id\n    name\n    databaseId\n    columnType\n  }\n}\n\nfragment ShowNormParagraph_normParagraph on NormParagraph {\n  id\n  normColumnAnswers(normOrganisationId: $id) {\n    id\n    databaseId\n    answer\n    normColumnId\n  }\n  normExplanation(normOrganisationId: $id) {\n    isApplicable\n    isImplemented\n    content\n    reasonLaw\n    reasonContract\n    reasonBestPractices\n    reasonRisk\n    id\n  }\n}\n"
  }
};
})();

node.hash = "cf16b01d7b4f8b527fb84d078d954d90";

module.exports = node;
