/**
 * @generated SignedSource<<8e82753bfa925c2a9791169e41e5015d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uuid"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "uuid"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v6 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v7/*: any*/)
],
v9 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v5/*: any*/),
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AnonymousDeviationFormQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AnonymousDeviationFormItems",
        "kind": "LinkedField",
        "name": "anonymousDeviationFormItems",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BusinessUnit",
            "kind": "LinkedField",
            "name": "businessUnits",
            "plural": true,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DataOption",
            "kind": "LinkedField",
            "name": "dataOptions",
            "plural": true,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DataSubject",
            "kind": "LinkedField",
            "name": "dataSubjects",
            "plural": true,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ManualParagraph",
            "kind": "LinkedField",
            "name": "manualParagraphs",
            "plural": true,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "NormParagraph",
            "kind": "LinkedField",
            "name": "normParagraphs",
            "plural": true,
            "selections": (v6/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AnonymousDeviationFormQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AnonymousDeviationFormItems",
        "kind": "LinkedField",
        "name": "anonymousDeviationFormItems",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BusinessUnit",
            "kind": "LinkedField",
            "name": "businessUnits",
            "plural": true,
            "selections": (v8/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DataOption",
            "kind": "LinkedField",
            "name": "dataOptions",
            "plural": true,
            "selections": (v8/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DataSubject",
            "kind": "LinkedField",
            "name": "dataSubjects",
            "plural": true,
            "selections": (v8/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ManualParagraph",
            "kind": "LinkedField",
            "name": "manualParagraphs",
            "plural": true,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "NormParagraph",
            "kind": "LinkedField",
            "name": "normParagraphs",
            "plural": true,
            "selections": (v9/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fe516d2374bd98576300da9581260a9d",
    "id": null,
    "metadata": {},
    "name": "AnonymousDeviationFormQuery",
    "operationKind": "query",
    "text": "query AnonymousDeviationFormQuery(\n  $uuid: String!\n) {\n  anonymousDeviationFormItems(uuid: $uuid) {\n    businessUnits {\n      databaseId\n      name\n      id\n    }\n    dataOptions {\n      databaseId\n      name\n      id\n    }\n    dataSubjects {\n      databaseId\n      name\n      id\n    }\n    manualParagraphs {\n      databaseId\n      name\n      number\n      id\n    }\n    normParagraphs {\n      databaseId\n      name\n      number\n      id\n    }\n  }\n}\n"
  }
};
})();

node.hash = "56ba1da3371706fd00fd64b30307a5b5";

module.exports = node;
