/**
 * @generated SignedSource<<73df7d44a446480fec0d852b5afa0f21>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uuid"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "uuid"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "confidential",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  (v3/*: any*/),
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v7 = [
  (v4/*: any*/),
  (v3/*: any*/)
],
v8 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "number",
    "storageKey": null
  },
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AnonymousDeviationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Deviation",
        "kind": "LinkedField",
        "name": "anonymousDeviation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DeviationData_deviation"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AnonymousDeviationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Deviation",
        "kind": "LinkedField",
        "name": "anonymousDeviation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cause",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "consequences",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "consequencesClients",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "consequencesEmployees",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "consequencesInvolved",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "consequencesOrganisation",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "datetimeOfDetection",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "datetimeOfIncident",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deviationId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deviationType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "encrypted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "focusArea",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasInaccessibleDeviation",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "involvedOrganisations",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numberOfPersons",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "reportedByEmail",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "reportedByFirstname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "reportedByLastname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "reportedByPhone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "takenActions",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "assignedTo",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Attachment",
            "kind": "LinkedField",
            "name": "attachments",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "filename",
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AttachmentUrl",
            "kind": "LinkedField",
            "name": "attachmentUrls",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BusinessUnit",
            "kind": "LinkedField",
            "name": "businessUnit",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "createdBy",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DataOption",
            "kind": "LinkedField",
            "name": "dataOptions",
            "plural": true,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DataSubject",
            "kind": "LinkedField",
            "name": "dataSubjects",
            "plural": true,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Deviation",
            "kind": "LinkedField",
            "name": "deviation",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviationCategory",
            "kind": "LinkedField",
            "name": "deviationCategory",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ManualParagraph",
            "kind": "LinkedField",
            "name": "manualParagraphs",
            "plural": true,
            "selections": (v8/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "NormParagraph",
            "kind": "LinkedField",
            "name": "normParagraphs",
            "plural": true,
            "selections": (v8/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organisation",
            "kind": "LinkedField",
            "name": "organisation",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "companyName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasManual",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviationCategory",
            "kind": "LinkedField",
            "name": "secondDeviationCategory",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviationCategory",
            "kind": "LinkedField",
            "name": "thirdDeviationCategory",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d33aa043c4f3b7fc91ee09a20acd6fb0",
    "id": null,
    "metadata": {},
    "name": "AnonymousDeviationQuery",
    "operationKind": "query",
    "text": "query AnonymousDeviationQuery(\n  $uuid: String!\n) {\n  anonymousDeviation(uuid: $uuid) {\n    confidential\n    ...DeviationData_deviation\n    id\n  }\n}\n\nfragment AttachmentLink_attachment on Attachment {\n  filename\n  url\n}\n\nfragment DeviationData_deviation on Deviation {\n  cause\n  consequences\n  consequencesClients\n  consequencesEmployees\n  consequencesInvolved\n  consequencesOrganisation\n  createdAt\n  description\n  datetimeOfDetection\n  datetimeOfIncident\n  deviationId\n  deviationType\n  encrypted\n  focusArea\n  hasInaccessibleDeviation\n  involvedOrganisations\n  numberOfPersons\n  reportedByEmail\n  reportedByFirstname\n  reportedByLastname\n  reportedByPhone\n  status\n  takenActions\n  assignedTo {\n    id\n    name\n  }\n  attachments {\n    id\n    ...AttachmentLink_attachment\n  }\n  attachmentUrls {\n    id\n    url\n  }\n  businessUnit {\n    name\n    id\n  }\n  createdBy {\n    name\n    id\n  }\n  dataOptions {\n    id\n    name\n  }\n  dataSubjects {\n    id\n    name\n  }\n  deviation {\n    name\n    id\n  }\n  deviationCategory {\n    name\n    id\n  }\n  manualParagraphs {\n    name\n    number\n    id\n  }\n  normParagraphs {\n    name\n    number\n    id\n  }\n  organisation {\n    companyName\n    hasManual\n    id\n  }\n  secondDeviationCategory {\n    name\n    id\n  }\n  thirdDeviationCategory {\n    name\n    id\n  }\n}\n"
  }
};
})();

node.hash = "19f6917446bb2dc5b7657163190b02ea";

module.exports = node;
