import { createGlobalStyle } from 'styled-components';
import '@fontsource/nunito';
import '@fontsource/nunito/800.css';
import '@fontsource/nunito/600.css';

import { theme } from './theme';

export const GlobalStyle = createGlobalStyle`
  :root {
    --body-color: ${theme.color};
    --body-bgcolor: ${theme.bgColor};
    --card-bgcolor: ${theme.card.bgcolor};
    --shadow-color: ${theme.shadow.primary};
    --second-shadow-color: ${theme.shadow.secondary};
    --primary-color: hsl(${theme.primary.colorH}, ${theme.primary.colorS}, ${theme.primary.colorL});
    --primary-color-h: ${theme.primary.colorH};
    --primary-color-s: ${theme.primary.colorS};
    --primary-color-l: ${theme.primary.colorL};
    --primary-color-text: ${theme.primary.color};
    --secondary-color: hsl(${theme.secondary.colorH}, ${theme.secondary.colorS}, ${theme.secondary.colorL});
    --secondary-color-h: ${theme.secondary.colorH};
    --secondary-color-s: ${theme.secondary.colorS};
    --secondary-color-l: ${theme.secondary.colorL};
    --secondary-color-text: ${theme.secondary.color};
    --tertiary-color: hsl(${theme.tertiary.colorH}, ${theme.tertiary.colorS}, ${theme.tertiary.colorL});
    --tertiary-color-h: ${theme.tertiary.colorH};
    --tertiary-color-s: ${theme.tertiary.colorS};
    --tertiary-color-l: ${theme.tertiary.colorL};
    --tertiary-color-text: ${theme.tertiary.color};
    --quartiary-color: hsl(${theme.quartiary.colorH}, ${theme.quartiary.colorS}, ${theme.quartiary.colorL});
    --quartiary-color-h: ${theme.quartiary.colorH};
    --quartiary-color-s: ${theme.quartiary.colorS};
    --quartiary-color-l: ${theme.quartiary.colorL};
    --quartiary-color-text: ${theme.quartiary.color};
    --success-color: hsl(${theme.success.colorH}, ${theme.success.colorS}, ${theme.success.colorL});
    --success-color-h: ${theme.success.colorH};
    --success-color-s: ${theme.success.colorS};
    --success-color-l: ${theme.success.colorL};
    --success-color-text: ${theme.success.color};
    --danger-color: hsl(${theme.danger.colorH}, ${theme.danger.colorS}, ${theme.danger.colorL});
    --danger-color-h: ${theme.danger.colorH};
    --danger-color-s: ${theme.danger.colorS};
    --danger-color-l: ${theme.danger.colorL};
    --danger-color-text: ${theme.danger.color};
    --warning-color: hsl(${theme.warning.colorH}, ${theme.warning.colorS}, ${theme.warning.colorL});
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  body {
    background-color: var(--body-bgcolor);
    color: var(--body-color);
    font-family: "Nunito";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    overflow-x: hidden;
  }

  [tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important;
  }

  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  abbr[title],
  abbr[data-original-title] {
    text-decoration: underline;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    text-decoration-skip-ink: none;
  }

  address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
  }

  ol,
  ul,
  dl {
    margin-top: 0;
    margin-bottom: 1rem;
    padding-inline-start: 1rem;
  }

  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0;
  }

  dt {
    font-weight: 700;
  }

  dd {
    margin-bottom: .5rem;
    margin-left: 0;
  }

  blockquote {
    margin: 0 0 1rem;
  }

  b,
  strong {
    font-weight: bolder;
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }

  sub {
    bottom: -.25em;
  }

  sup {
    top: -.5em;
  }

  h1, .h1 {
    font-size: 2.25rem;
  }

  h2, .h2 {
    font-size: 2rem;
  }

  h3, .h3 {
    font-size: 1.75rem;
    font-weight: 600;
  }

  h4, .h4 {
    font-size: 1.5rem;
    font-weight: 600;
  }

  h5, .h5 {
    font-size: 1.25rem;
    font-weight: 600;
  }

  h6, .h6 {
    font-size: 1rem;
    font-weight: 600;
  }

  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }

  legend {
    padding: 0;
  }

  button, input, optgroup, select, textarea {
    color: inherit;
    font: inherit;
    margin: 0;
  }

  img {
    max-width: 100%;
  }
`;
